import React, { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { FarzaaContext } from "../../context/FarzaaContext";
import { Link } from "react-router-dom";

const LayerCakeSlider = () => {
  const {
    addToCakeCart,
    addToCakeWishlist,
    handleProductViewOpen,
    cakeListArray,
  } = useContext(FarzaaContext);

  return (
    <section id="portfolio" className="fz-3-layer-cake-section">
      <div className="container">
        <div className="fz-3-section-heading">
          <div className="section-heading__txt">
            <h2 style={{ color: 'black' }} className="fz-section-title">Portfolio</h2>
            <p style={{ color: 'black', fontSize: '20px' }} className="fz-section-sub-title">
              We blend in the vibrant flavors of Vietnamese, Japanese, Thai and French cuisines into one seamless, upscale culinary experience.
            </p>
          </div>

          <div className="section-heading__actions">
            <div className="fz-3-layer-cake-slider-nav fz-3-slider-nav">
              <button className="owl-prev">
                <i className="fa-solid fa-angle-left"></i>
              </button>
              <button className="owl-next">
                <i className="fa-solid fa-angle-right"></i>
              </button>
            </div>
          </div>

        </div>


        <Swiper
          slidesPerView={4}
          spaceBetween={30}
          loop={true}
          autoplay={{ delay: 7000 }}
          navigation={{
            prevEl: ".owl-prev",
            nextEl: ".owl-next",
          }}
          modules={[Navigation, Autoplay]}
          className="fz-3-layer-cake-slider owl-carousel"
          breakpoints={{
            0: {
              slidesPerView: 1,
              centeredSlides: true,
            },

            480: {
              slidesPerView: 2,
              centeredSlides: false,
            },

            576: {
              slidesPerView: 2,
            },

            768: {
              slidesPerView: 3,
            },

            992: {
              slidesPerView: 4,
            },
          }}
        >
          {cakeListArray.slice(0, 16).map((item) => {
            return (
              <SwiperSlide className="fz-3-single-product" key={item.id}>
                <div style={{ cursor: 'pointer' }} className="fz-3-single-product__img">
                  <img src={item.imgSrc} alt="Product Image" />
                  <div className="fz-3-single-product__actions"></div>
                  <span className="fz-3-hover-span">{item.desc}</span>
                </div>
              </SwiperSlide>
            );
          })}

        </Swiper>
      </div>
    </section>
  );
};

export default LayerCakeSlider;
