import React, { useContext, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { FarzaaContext } from "../../context/FarzaaContext";
import { Zoom } from "yet-another-react-lightbox/plugins";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const Menu = () => {
  const { cakeListArray } = useContext(FarzaaContext);

  // State để quản lý lightbox
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  // Mảng chứa tất cả ảnh trong gallery
  const imageList = cakeListArray.slice(17, 23).map(item => item.imgSrc);

  return (
    <section id="portfolio" className="fz-3-layer-cake-section">
      <div className="container">
        <div className="fz-3-section-heading">
          <div className="section-heading__txt">
            <h2 style={{ color: 'black' }} className="fz-section-title">Menu</h2>
            <p style={{ color: 'black', fontSize: '20px' }} className="fz-section-sub-title">
              Our menu features a diverse selection of packages, combining the vibrant flavors of Vietnamese, Japanese, Thai, and
              French cuisines, offering a variety of choices for a tailored culinary experience.
            </p>
          </div>
        </div>

        <Swiper
          slidesPerView={4}
          spaceBetween={30}
          loop={true}

          navigation={{
            prevEl: ".owl-prev",
            nextEl: ".owl-next",
          }}
          modules={[Navigation, Autoplay]}
          className="fz-3-layer-cake-slider owl-carousel"
          breakpoints={{
            0: { slidesPerView: 1, centeredSlides: true },
            480: { slidesPerView: 2, centeredSlides: false },
            576: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            992: { slidesPerView: 4 },
          }}
        >
          {cakeListArray.slice(24, 30).map((item, index) => (
            <SwiperSlide className="fz-3-single-product" key={item.id}>
              <div
                style={{ cursor: 'pointer' }}
                className="fz-3-single-product__img"
                onClick={() => {
                  setPhotoIndex(index);
                  setIsOpen(true);
                }}
              >
                <img src={item.imgSrc} alt="Product Image" />
                <span className="fz-3-hover-span">{item.desc}</span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Hiển thị Lightbox nếu isOpen === true */}
      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={imageList.map((src) => ({ src }))}
          index={photoIndex}
          on={{
            view: ({ index }) => setPhotoIndex(index),
          }}
          plugins={[Zoom]}
          zoom={{ maxZoomPixelRatio: 3 ,
            scrollToZoom: true,
          }}
          
        />
      )}

    </section>
  );
};

export default Menu;
