const firstBannerData = [
  {
    id: 0,
    title: "The Perfect Door For Every Occasion",
    imgSrc: "assets/images/fz-3-banner-img-3.jpg",
  },
  {
    id: 1,
    title: "Make your entrance a grand one",
    imgSrc: "assets/images/fz-3-banner-img-2.jpg",
  },
  {
    id: 2,
    title: "The Secret Key to a Stylish Home",
    imgSrc: "assets/images/fz-3-banner-img.png",
  },
];

const brandList = [
  {
    id: 0,
    imgSrc: "assets/images/fz-3-brand-1.png",
  },
  {
    id: 1,
    imgSrc: "assets/images/fz-3-brand-2.png",
  },
  {
    id: 2,
    imgSrc: "assets/images/fz-3-brand-3.png",
  },
  {
    id: 3,
    imgSrc: "assets/images/fz-3-brand-4.png",
  },
  {
    id: 4,
    imgSrc: "assets/images/fz-3-brand-5.png",
  },
  {
    id: 5,
    imgSrc: "assets/images/fz-3-brand-1.png",
  },
  {
    id: 6,
    imgSrc: "assets/images/fz-3-brand-2.png",
  },
  {
    id: 7,
    imgSrc: "assets/images/fz-3-brand-3.png",
  },
];

const thirdBannerData = [
  {
    id: 0,
    title: "Private Fusion Chef",
    desc: "We blend in the vibrant flavors of Vietnamese, Japanese, Thai and French cuisines into one seamless, upscale culinary experience. This unique Asian French fusion approach goes beyond traditional dining, offering a distinctive and innovative concept that sets Private Fusion Chef apart from others.",
    firstImgSrc: "assets/images/card-img-1.png",
    firstPrice: 149.0,
    secondImgSrc: "assets/images/card-img-2.jpg",
    secondPrice: 129.0,
  },
  {
    id: 1,
    title: "Dining Your Way",
    desc: "A bespoke menu tailored exclusively to your preferences and designed to reflect your unique tastes.",
    firstImgSrc: "assets/images/card-img-3.jpg",
    firstPrice: 149.0,
    secondImgSrc: "assets/images/card-img-4.jpg",
    secondPrice: 129.0,
  },
  {
    id: 2,
    title: "Luxurious Dining Redefined",
    desc: "Luxurious and thoughtfully plated dishes designed to impress.",
    firstImgSrc: "assets/images/card-img-5.jpg",
    firstPrice: 149.0,
    secondImgSrc: "assets/images/card-img-6.jpg",
    secondPrice: 129.0,
  },
];

const accordionList = [
  {
    id: 0,
    title: "How To Make Website Easy Edit?",
  },
  {
    id: 1,
    title: "In How Many Month you Update the website?",
  },
  {
    id: 2,
    title: "How To Check your Shopping Cart?",
  },
  {
    id: 3,
    title: "Where To Get Your Contact Support?",
  },
  {
    id: 4,
    title: "Can I Make A Direct Call For Support?",
  },
  {
    id: 5,
    title: "How To Make Website Easy Edit?",
  },
  {
    id: 6,
    title: "Where Is Your Location?",
  },
  {
    id: 7,
    title: "When Will I Receive My Order?",
  },
  {
    id: 8,
    title: "What Are The Shipping Options?",
  },
];

const allProductList = [
  {
    id: 0,
    imgSrc: "assets/images/fz-3-pro-2.png",
    name: "Dark Brown Door",
    category: "Solid Color Door",
    price: 740.0,
    isInWishlist: false,
    quantity: 1,
  },
  {
    id: 1,
    imgSrc: "assets/images/fz-3-pro-3.png",
    name: "Light Wood Door",
    category: "Double Layer Door",
    price: 550.0,
    isInWishlist: false,
    quantity: 1,
  },
  {
    id: 2,
    imgSrc: "assets/images/fz-3-pro-4.png",
    name: "Dark Stripe Door",
    category: "Chinese Door",
    price: 850.0,
    isInWishlist: false,
  },
  {
    id: 3,
    imgSrc: "assets/images/fz-3-pro-5.png",
    name: "Modern Fir Door",
    category: "Plastic Door",
    price: 440.0,
    isInWishlist: false,
  },
  {
    id: 4,
    imgSrc: "assets/images/fz-3-cat-4.png",
    name: "Brown Wood Door",
    category: "Solid Color Door",
    price: 680.0,
    isInWishlist: false,
  },
  {
    id: 5,
    imgSrc: "assets/images/fz-3-cat-4.png",
    name: "Brown Layer Door",
    category: "Solid Color Door",
    price: 730.0,
    isInWishlist: false,
  },
  {
    id: 6,
    imgSrc: "assets/images/fz-3-cat-3.png",
    name: "Dark Brown Door",
    category: "Bi-fold Doors",
    price: 940.0,
    isInWishlist: false,
  },
  {
    id: 7,
    imgSrc: "assets/images/fz-3-cat-2.png",
    name: "Wood Stripe Door",
    category: "Panel Door",
    price: 480.0,
    isInWishlist: false,
  },
  {
    id: 8,
    imgSrc: "assets/images/fz-3-cat-1.png",
    name: "Light Brown Door",
    category: "Wooden Door",
    price: 390.0,
    isInWishlist: false,
  },
  {
    id: 9,
    imgSrc: "assets/images/fz-3-pro-1.png",
    name: "Plastic Brown Door",
    category: "Plastic Door",
    price: 240.0,
    isInWishlist: false,
  },
  {
    id: 10,
    imgSrc: "assets/images/fz-3-pro-6.png",
    name: "Modern Black Door",
    category: "Steel Door",
    price: 430.0,
    isInWishlist: false,
  },
  {
    id: 11,
    imgSrc: "assets/images/fz-3-pro-7.png",
    name: "Plastic Black Door",
    category: "Security Door",
    price: 580.0,
    isInWishlist: false,
  },
  {
    id: 12,
    imgSrc: "assets/images/fz-3-pro-5.png",
    name: "Wooden Design Door",
    category: "Plastic Door",
    price: 340.0,
    isInWishlist: false,
  },
  {
    id: 13,
    imgSrc: "assets/images/fz-3-cat-4.png",
    name: "Rustic Theme Door",
    category: "Wooden Door",
    price: 690.0,
    isInWishlist: false,
  },
  {
    id: 14,
    imgSrc: "assets/images/fz-3-cat-2.png",
    name: "Wood Stripe Door",
    category: "Wooden Door",
    price: 350.0,
    isInWishlist: false,
  },
  {
    id: 15,
    imgSrc: "assets/images/feat-pro-1.png",
    name: "Brown Solid Door",
    category: "Wooden Door",
    price: 630.0,
    oldPrice: 900.0,
    isInWishlist: false,
    quantity: 1,
  },
  {
    id: 16,
    imgSrc: "assets/images/feat-pro-2.png",
    name: "Brown Antique Door",
    category: "Wooden Door",
    price: 672.0,
    oldPrice: 960.0,
    isInWishlist: false,
    quantity: 1,
  },
];

const blogList = [
  {
    id: 0,
    imgSrc: "assets/images/blogs-blog-1.png",
    category: "Electronic",
    date: "June 21, 2023",
    title:
      "An electronic product is a device that uses electricity to perform a function.",
    desc: "Electronic products often contain a range of components, including processors, memory, and input/output devices, and they may be powered by batteries or plugged into an electrical outlet. Many electronic products rely on software programs to function and may be connected to the internet.",
  },
  {
    id: 1,
    imgSrc: "assets/images/blogs-blog-2.png",
    category: "Furniture",
    date: "June 21, 2023",
    title:
      "There are many furniture blogs that provide information and inspiration on furniture",
    desc: "These blogs often feature articles on a variety of topics related to furniture, such as how to choose the right furniture for your space, how to care for different types of furniture, and how to mix and match different styles to create a cohesive look...",
  },
  {
    id: 2,
    imgSrc: "assets/images/blogs-blog-3.png",
    category: "Fashion",
    date: "June 21, 2023",
    title:
      "Fashion refers to the styles and trends in clothing footwear, accessories ersonal expression",
    desc: "Electronic products often contain a range of components, including processors, memory, and input/output devices, and they may be powered by batteries or plugged into an electrical outlet. Many electronic products rely on software programs to function and may be connected to the internet.",
  },
  {
    id: 3,
    imgSrc: "assets/images/blogs-blog-4.png",
    category: "Food",
    date: "June 22, 2023",
    title: "Exploring Culinary Delights: A Journey into Exquisite Tastes",
    desc: "Food is not just sustenance; it's an art, a culture, and a way of life. From street food stalls to Michelin-starred restaurants, this blog dives into the rich tapestry of flavors, ingredients, and culinary traditions that make up our global food landscape.",
  },
  {
    id: 4,
    imgSrc: "assets/images/blogs-blog-5.png",
    category: "Travel",
    date: "June 22, 2023",
    title: "Wanderlust Chronicles: Tales of Adventures and Discoveries",
    desc: "Embark on a virtual journey to faraway lands, hidden gems, and breathtaking landscapes. This travel blog captures the spirit of exploration, offering insights into unforgettable destinations, travel tips, and personal stories from globetrotters around the world.",
  },
];

const ornamentList = [
  {
    id: 0,
    imgSrc: "assets/images/img-1.jpg",
    category: "Ring",
    name: "Flower Shaped Diamond Ring",
    price: 640.0,
    oldPrice: 780.0,
    isInWishlist: false,
  },
  {
    id: 1,
    imgSrc: "assets/images/img-2.jpg",
    category: "Ring",
    name: "Crown Shaped Diamond Ring",
    price: 950.0,
    oldPrice: 980.0,
    isInWishlist: false,
  },
  {
    id: 2,
    imgSrc: "assets/images/img-3.jpg",
    category: "Necklace",
    name: "Diamond Cross Necklace",
    price: 760.0,
    oldPrice: 820.0,
    isInWishlist: false,
  },
  {
    id: 3,
    imgSrc: "assets/images/img-4.jpg",
    category: "Necklace",
    name: "Sapphire Diamond Necklace",
    price: 830.0,
    oldPrice: 880.0,
    isInWishlist: false,
  },
  {
    id: 4,
    imgSrc: "assets/images/img-5.jpg",
    category: "Hair Clip",
    name: "Diamond Ingraved Ribbon",
    price: 450.0,
    oldPrice: 480.0,
    isInWishlist: false,
  },
  {
    id: 5,
    imgSrc: "assets/images/img-6.jpg",
    category: "Ring",
    name: "Diamond Engagement Ring",
    price: 800.0,
    oldPrice: 850.0,
    isInWishlist: false,
  },
  {
    id: 6,
    imgSrc: "assets/images/img-7.jpg",
    category: "Bracelet",
    name: "Diamond Ingraved Bracelet",
    price: 640.0,
    oldPrice: 780.0,
    isInWishlist: false,
  },
  {
    id: 7,
    imgSrc: "assets/images/img-8.jpg",
    category: "Bracelet",
    name: "Diamond Leather Bracelet",
    price: 640.0,
    oldPrice: 780.0,
    isInWishlist: false,
  },
];

const allCakeList = [
  {
    id: 0,
    isInWishlist: false,
    imgSrc: "assets/images/food1.jpg",
    name: "Homemade Strawberry Cake",
    desc: "We will transform your special occasions into unforgettable experience—right in the comfort of your own home.",
    price: 150.0,
    oldPrice: 180.0,
  },
  {
    id: 1,
    isInWishlist: false,
    imgSrc: "assets/images/food2.jpg",
    name: "Homemade Chocolate Cake",
    desc: "Your home becomes a fine dining destination.",
    price: 70.0,
    oldPrice: 85.0,
  },
  {
    id: 2,
    isInWishlist: false,
    imgSrc: "assets/images/food3.jpg",
    name: "Homemade Red Velvet Cake",
    desc: "A Luxurious and intimate dining experience in your home.",
    price: 75.0,
    oldPrice: 85.0,
  },
  {
    id: 3,
    isInWishlist: false,
    imgSrc: "assets/images/food4.jpg",
    name: "Homemade Caramel Cake",
    desc: "A bespoke menu tailored exclusively to your preferences and designed to reflect your unique tastes.",
    price: 80.0,
    oldPrice: 90.0,
  },
  {
    id: 4,
    isInWishlist: false,
    imgSrc: "assets/images/food5.jpg",
    name: "Homemade Strawberry Cake",
    desc: "A dining experience that brings the elegance of international fusion cuisine into the heart of your celebration.",
    price: 150.0,
    oldPrice: 180.0,
  },
  {
    id: 5,
    isInWishlist: false,
    imgSrc: "assets/images/food6.jpg",
    name: "Homemade Chocolate Cake",
    desc: "Luxurious and thoughtfully plated dishes designed to impress.",
    price: 70.0,
    oldPrice: 85.0,
  },

  {
    id: 6,
    isInWishlist: false,
    imgSrc: "assets/images/food7.jpg",
    name: "Homemade Pumpkin Cake",
    desc: "Flavor combination that intrigues and delight.",
    price: 50.0,
    oldPrice: 60.0,
  },
  {
    id: 7,
    isInWishlist: false,
    imgSrc: "assets/images/food8.jpg",
    name: "Homemade Pound Cake",
    desc: "A true culinary adventure for you and your guests.",
    price: 40.0,
    oldPrice: 50.0,
  },
  {
    id: 8,
    isInWishlist: false,
    imgSrc: "assets/images/food9.jpg",
    name: "Homemade Chocolate Pie",
    desc: "Love in seeing the happiness that shared meals bring to families and friends.",
    price: 70.0,
    oldPrice: 80.0,
  },

  {
    id: 9,
    isInWishlist: false,
    imgSrc: "assets/images/food15.jpg",
    name: "Strawberry Cup Cup Cake",
    desc: "Let’s make your next gathering a culinary adventure.  ",
    price: 40.0,
    oldPrice: 50.0,
  },
  {
    id: 10,
    isInWishlist: false,
    imgSrc: "assets/images/food16.jpg",
    name: "Chocolate Cream Cup Cake",
    desc: "Every meal is an opportunity to create lasting memories, where food and conversation come together seamlessly.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 11,
    isInWishlist: false,
    imgSrc: "assets/images/food17.jpg",
    name: "Chocolate Cream Cup Cake",
    desc: "Impress your guests with a fusion of global cuisines that elevate your event to an unforgettable level.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 12,
    isInWishlist: false,
    imgSrc: "assets/images/food18.jpg",
    name: "Chocolate Cream Cup Cake",
    desc: "We’re passionate about bringing families and friends together over meals that spark joy and connection.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 13,
    isInWishlist: false,
    imgSrc: "assets/images/food19.jpg",
    name: "Chocolate Cream Cup Cake",
    desc: "Indulge in the exquisite taste of fine dining without stepping outside your door.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 14,
    isInWishlist: false,
    imgSrc: "assets/images/IMG_9153.jpg",
    name: "Chocolate Cream Cup Cake",
    desc: "Our specially crafted meals will elevate your celebration to new heights.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 15,
    isInWishlist: false,
    imgSrc: "assets/images/IMG_9169.jpg",
    name: "Chocolate Cream Cup Cake",
    desc: "Elevate your event with a one-of-a-kind dining experience, tailored to your taste.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 16,
    isInWishlist: false,
    imgSrc: "assets/images/IMG_9175.jpg",
    name: "Chocolate Cream Cup Cake",
    desc: "A dining experience that combines the art of cooking with the warmth of home.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 17,
    isInWishlist: false,
    imgSrc: "assets/images/IMG_1111.jpg",
    name: "Chocolate Cream Cup Cake",
    desc: "Click to view the full menu.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 18,
    isInWishlist: false,
    imgSrc: "assets/images/IMG_1112.jpg",
    name: "Chocolate Cream Cup Cake",
    desc: "Click to view the full menu.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 19,
    isInWishlist: false,
    imgSrc: "assets/images/IMG_1113.jpg",
    name: "Chocolate Cream Cup Cake",
    desc: "Click to view the full menu.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 20,
    isInWishlist: false,
    imgSrc: "assets/images/IMG_1114.jpg",
    name: "Chocolate Cream Cup Cake",
    desc: "Click to view the full menu.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 21,
    isInWishlist: false,
    imgSrc: "assets/images/IMG_1115.jpg",
    name: "Chocolate Cream Cup Cake",
    desc: "Click to view the full menu.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 22,
    isInWishlist: false,
    imgSrc: "assets/images/IMG_1116.jpg",
    name: "Chocolate Cream Cup Cake",
    desc: "Click to view the full menu.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 23,
    isInWishlist: false,
    imgSrc: "assets/images/IMG_1117.jpg",
    name: "Chocolate Cream Cup Cake",
    desc: "Click to view the full menu.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 24,
    isInWishlist: false,
    imgSrc: "assets/images/1.png",
    name: "Chocolate Cream Cup Cake",
    desc: "Click to view the full menu.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 25,
    isInWishlist: false,
    imgSrc: "assets/images/2.png",
    name: "Chocolate Cream Cup Cake",
    desc: "Click to view the full menu.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 26,
    isInWishlist: false,
    imgSrc: "assets/images/3.png",
    name: "Chocolate Cream Cup Cake",
    desc: "Click to view the full menu.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 27,
    isInWishlist: false,
    imgSrc: "assets/images/4.png",
    name: "Chocolate Cream Cup Cake",
    desc: "Click to view the full menu.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 28,
    isInWishlist: false,
    imgSrc: "assets/images/5.png",
    name: "Chocolate Cream Cup Cake",
    desc: "Click to view the full menu.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 29,
    isInWishlist: false,
    imgSrc: "assets/images/6.png",
    name: "Chocolate Cream Cup Cake",
    desc: "Click to view the full menu.",
    price: 45.0,
    oldPrice: 50.0,
  },
  {
    id: 30,
    isInWishlist: false,
    imgSrc: "assets/images/7.png",
    name: "Chocolate Cream Cup Cake",
    desc: "Click to view the full menu.",
    price: 45.0,
    oldPrice: 50.0,
  },
  
];

const productDetail = [
  {
    id: 0,
    imgSrc: "assets/images/fz-1-banner-bg-1.png",
    name: "Bento Cake Ultimate Chocolate 200gm",
    price: 750.0,
  },
];

const electricalProduct = [
  {
    id: 1,
    img: "assets/images/food21.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
  {
    id: 2,
    img: "assets/images/food22.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
  {
    id: 3,
    img: "assets/images/food23.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
  {
    id: 4,
    img: "assets/images/food24.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
  {
    id: 5,
    img: "assets/images/food25.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
  {
    id: 6,
    img: "assets/images/food26.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
  {
    id: 7,
    img: "assets/images/food27.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
  {
    id: 8,
    img: "assets/images/food28.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
];

const ledBulbData = [
  {
    id: 1,
    img: "assets/images/led-bulb-1.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
  {
    id: 2,
    img: "assets/images/led-bulb-2.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
  {
    id: 3,
    img: "assets/images/led-bulb-3.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
  {
    id: 4,
    img: "assets/images/led-bulb-4.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
  {
    id: 5,
    img: "assets/images/led-bulb-1.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
];
const compactDeviceData = [
  {
    id: 1,
    img: "assets/images/compact-device-1.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
  {
    id: 2,
    img: "assets/images/compact-device-2.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
  {
    id: 3,
    img: "assets/images/compact-device-3.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
  {
    id: 4,
    img: "assets/images/compact-device-4.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
  {
    id: 5,
    img: "assets/images/compact-device-1.png",
    name: "1.0mm Grey Cable - 100M",
    price: 640,
    oldPrice: 789,
  },
];

const testimonialData = [
  {
    id: 1,
    img: "assets/images/user-1.png",
    name: "Marisa Tomei",
    testimony:
      "I recently purchased the XYZ electric kettle and have been thoroughly impressed with its performance. The kettle heats up quickly and has an easy-to-use temperature control feature that allows me to make tea at the perfect temperature every time",
  },
  {
    id: 2,
    img: "assets/images/user-1.png",
    name: "Marisa Tomei",
    testimony:
      "I recently purchased the XYZ electric kettle and have been thoroughly impressed with its performance. The kettle heats up quickly and has an easy-to-use temperature control feature that allows me to make tea at the perfect temperature every time",
  },
  {
    id: 3,
    img: "assets/images/user-1.png",
    name: "Marisa Tomei",
    testimony:
      "I recently purchased the XYZ electric kettle and have been thoroughly impressed with its performance. The kettle heats up quickly and has an easy-to-use temperature control feature that allows me to make tea at the perfect temperature every time",
  },
  {
    id: 4,
    img: "assets/images/user-1.png",
    name: "Marisa Tomei",
    testimony:
      "I recently purchased the XYZ electric kettle and have been thoroughly impressed with its performance. The kettle heats up quickly and has an easy-to-use temperature control feature that allows me to make tea at the perfect temperature every time",
  },
  {
    id: 5,
    img: "assets/images/user-1.png",
    name: "Marisa Tomei",
    testimony:
      "I recently purchased the XYZ electric kettle and have been thoroughly impressed with its performance. The kettle heats up quickly and has an easy-to-use temperature control feature that allows me to make tea at the perfect temperature every time",
  },
  {
    id: 6,
    img: "assets/images/user-1.png",
    name: "Marisa Tomei",
    testimony:
      "I recently purchased the XYZ electric kettle and have been thoroughly impressed with its performance. The kettle heats up quickly and has an easy-to-use temperature control feature that allows me to make tea at the perfect temperature every time",
  },
  {
    id: 7,
    img: "assets/images/user-1.png",
    name: "Marisa Tomei",
    testimony:
      "I recently purchased the XYZ electric kettle and have been thoroughly impressed with its performance. The kettle heats up quickly and has an easy-to-use temperature control feature that allows me to make tea at the perfect temperature every time",
  },
];

const brandData = [
  "assets/images/fz-4-brand-1.png",
  "assets/images/fz-4-brand-2.png",
  "assets/images/fz-4-brand-3.png",
  "assets/images/fz-4-brand-4.png",
  "assets/images/fz-4-brand-5.png",
  "assets/images/fz-4-brand-1.png",
  "assets/images/fz-4-brand-2.png",
  "assets/images/fz-4-brand-3.png",
  "assets/images/fz-4-brand-4.png",
  "assets/images/fz-4-brand-5.png",
];
const brandData2 = [
  "assets/images/fz-5-brand-1.png",
  "assets/images/fz-5-brand-2.png",
  "assets/images/fz-5-brand-3.png",
  "assets/images/fz-5-brand-4.png",
  "assets/images/fz-5-brand-5.png",
  "assets/images/fz-5-brand-6.png",
  "assets/images/fz-5-brand-1.png",
  "assets/images/fz-5-brand-2.png",
  "assets/images/fz-5-brand-3.png",
  "assets/images/fz-5-brand-4.png",
  "assets/images/fz-5-brand-5.png",
  "assets/images/fz-5-brand-6.png",
];

const categoryData = [
  {
    id: 1,
    img: "assets/images/fz-cat-13.png",
    category: "Square",
  },
  {
    id: 2,
    img: "assets/images/fz-cat-14.png",
    category: "Cat-Eye",
  },
  {
    id: 3,
    img: "assets/images/fz-cat-15.png",
    category: "Oval",
  },
  {
    id: 4,
    img: "assets/images/fz-cat-16.png",
    category: "Round",
  },
  {
    id: 5,
    img: "assets/images/fz-cat-13.png",
    category: "Square",
  },
  {
    id: 6,
    img: "assets/images/fz-cat-14.png",
    category: "Cat-Eye",
  },
  {
    id: 7,
    img: "assets/images/fz-cat-15.png",
    category: "Oval",
  },
  {
    id: 8,
    img: "assets/images/fz-cat-16.png",
    category: "Round",
  },
];

const bannerData = [
  {
    id: 1,
    title: "We Sell The Parts That Power Your Life",
    desc: "Electronic Interfaces means access to operations support systems consisting of pre- ordering, ordering, provisioning.",
  },
  {
    id: 2,
    title: "We Sell The Parts That Power Your Life",
    desc: "Electronic Interfaces means access to operations support systems consisting of pre- ordering, ordering, provisioning.",
  },
  {
    id: 3,
    title: "We Sell The Parts That Power Your Life",
    desc: "Electronic Interfaces means access to operations support systems consisting of pre- ordering, ordering, provisioning.",
  },
];

const carPartsData = [
  {
    id: 1,
    discount: 30,
    title: "Aluminum Wheel AR-19 Tire Parts",
    img: "assets/images/fz-6-pro-1.png",
    price: 480,
    oldPrice: 780,
    category: "power-tools",
  },
  {
    id: 2,
    discount: 30,
    title: "Aluminum Wheel AR-19 Tire Parts",
    img: "assets/images/fz-6-pro-2.png",
    price: 480,
    oldPrice: 780,
    category: "hand-tools",
  },
  {
    id: 3,
    discount: 30,
    title: "Aluminum Wheel AR-19 Tire Parts",
    img: "assets/images/fz-6-pro-3.png",
    price: 480,
    oldPrice: 780,
    category: "power-tools",
  },
  {
    id: 4,
    discount: 30,
    title: "Aluminum Wheel AR-19 Tire Parts",
    img: "assets/images/fz-6-pro-4.png",
    price: 480,
    oldPrice: 780,
    category: "hand-tools",
  },
  {
    id: 5,
    discount: 30,
    title: "Aluminum Wheel AR-19 Tire Parts",
    img: "assets/images/fz-6-pro-5.png",
    price: 480,
    oldPrice: 780,
    category: "power-tools",
  },
  {
    id: 6,
    discount: 30,
    title: "Aluminum Wheel AR-19 Tire Parts",
    img: "assets/images/fz-6-pro-6.png",
    price: 480,
    oldPrice: 780,
    category: "hand-tools",
  },
  {
    id: 7,
    discount: 30,
    title: "Aluminum Wheel AR-19 Tire Parts",
    img: "assets/images/fz-6-pro-7.png",
    price: 480,
    oldPrice: 780,
    category: "power-tools",
  },
  {
    id: 8,
    discount: 30,
    title: "Aluminum Wheel AR-19 Tire Parts",
    img: "assets/images/fz-6-pro-8.png",
    price: 480,
    oldPrice: 780,
    category: "hand-tools",
  },
];

const blogData = [
  {
    id: 1,
    img: "assets/images/fz-6-blog-1.jpg",
    postedBy: "admin",
    category: "service",
    title: "Ultimate Car Accessories To Improve Your Car",
    date: "15 January, 2024",
  },
  {
    id: 2,
    img: "assets/images/fz-6-blog-2.jpg",
    postedBy: "admin",
    category: "service",
    title: "Ultimate Car Accessories To Improve Your Car",
    date: "15 January, 2024",
  },
  {
    id: 3,
    img: "assets/images/fz-6-blog-3.jpg",
    postedBy: "admin",
    category: "service",
    title: "Ultimate Car Accessories To Improve Your Car",
    date: "15 January, 2024",
  },
  {
    id: 4,
    img: "assets/images/fz-6-blog-1.jpg",
    postedBy: "admin",
    category: "service",
    title: "Ultimate Car Accessories To Improve Your Car",
    date: "15 January, 2024",
  },
];

const watchData = [
  {
    id: 1,
    img: "assets/images/fz-7-pro-1.jpg",
    category: "Smart Watch",
    title: "Apple Watch Series 3",
    slug: "#",
    price: 150,
  },
  {
    id: 2,
    img: "assets/images/fz-7-pro-2.jpg",
    category: "Smart Watch",
    title: "Apple Watch Series 3",
    slug: "#",
    price: 150,
  },
  {
    id: 3,
    img: "assets/images/fz-7-pro-3.jpg",
    category: "Smart Watch",
    title: "Apple Watch Series 3",
    slug: "#",
    price: 150,
  },
  {
    id: 4,
    img: "assets/images/fz-7-pro-4.jpg",
    category: "Smart Watch",
    title: "Apple Watch Series 3",
    slug: "#",
    price: 150,
  },
  {
    id: 5,
    img: "assets/images/fz-7-pro-5.jpg",
    category: "Smart Watch",
    title: "Apple Watch Series 3",
    slug: "#",
    price: 150,
  },
  {
    id: 6,
    img: "assets/images/fz-7-pro-6.jpg",
    category: "Smart Watch",
    title: "Apple Watch Series 3",
    slug: "#",
    price: 150,
  },
  {
    id: 7,
    img: "assets/images/fz-7-pro-7.jpg",
    category: "Smart Watch",
    title: "Apple Watch Series 3",
    slug: "#",
    price: 150,
  },
  {
    id: 8,
    img: "assets/images/fz-7-pro-8.jpg",
    category: "Smart Watch",
    title: "Apple Watch Series 3",
    slug: "#",
    price: 150,
  },
];

const blogData2 = [
  {
    id: 1,
    img: "assets/images/fz-7-blog-1.jpg",
    day: 15,
    month: "Jan",
    title: "Ultra Smart watch With Apple Logo 1",
    slug: "#",
  },
  {
    id: 2,
    img: "assets/images/fz-7-blog-2.jpg",
    day: 15,
    month: "Jan",
    title: "Ultra Smart watch With Apple Logo 1",
    slug: "#",
  },
  {
    id: 3,
    img: "assets/images/fz-7-blog-3.jpg",
    day: 15,
    month: "Jan",
    title: "Ultra Smart watch With Apple Logo 1",
    slug: "#",
  },
  {
    id: 4,
    img: "assets/images/fz-7-blog-1.jpg",
    day: 15,
    month: "Jan",
    title: "Ultra Smart watch With Apple Logo 1",
    slug: "#",
  },
  {
    id: 5,
    img: "assets/images/fz-7-blog-2.jpg",
    day: 15,
    month: "Jan",
    title: "Ultra Smart watch With Apple Logo 1",
    slug: "#",
  },
  {
    id: 6,
    img: "assets/images/fz-7-blog-3.jpg",
    day: 15,
    month: "Jan",
    title: "Ultra Smart watch With Apple Logo 1",
    slug: "#",
  },
];

const brandData3 = [
  {
    id: 1,
    img: "assets/images/fz-7-brand-1.png",
    slug: "#",
  },
  {
    id: 2,
    img: "assets/images/fz-7-brand-2.png",
    slug: "#",
  },
  {
    id: 3,
    img: "assets/images/fz-7-brand-3.png",
    slug: "#",
  },
  {
    id: 4,
    img: "assets/images/fz-7-brand-4.png",
    slug: "#",
  },
  {
    id: 5,
    img: "assets/images/fz-7-brand-1.png",
    slug: "#",
  },
  {
    id: 6,
    img: "assets/images/fz-7-brand-1.png",
    slug: "#",
  },
  {
    id: 7,
    img: "assets/images/fz-7-brand-1.png",
    slug: "#",
  },
  {
    id: 8,
    img: "assets/images/fz-7-brand-1.png",
    slug: "#",
  },
];

const bannerData2 = [
  {
    id: 1,
    title: "Top Quality Bicycle",
    img: "assets/images/fz-8-banner-img-1.png",
    productImg: "assets/images/fz-8-pro-1.png",
    productName: "Ember e6100",
    productPrice: 780,
  },
  {
    id: 2,
    title: "Top Quality Bicycle",
    img: "assets/images/fz-8-banner-img-2.png",
    productImg: "assets/images/fz-8-pro-1.png",
    productName: "Ember e6100",
    productPrice: 780,
  },
];
const cycleData = [
  {
    id: 1,
    img: "assets/images/fz-8-pro-4.png",
    slug: "ShopDetails",
    title: "Mountain E-Bike, S3",
    price: 650,
  },
  {
    id: 2,
    img: "assets/images/fz-8-pro-5.png",
    slug: "ShopDetails",
    title: "Mountain E-Bike, S3",
    price: 650,
  },
  {
    id: 3,
    img: "assets/images/fz-8-pro-6.png",
    slug: "ShopDetails",
    title: "Mountain E-Bike, S3",
    price: 650,
  },
  {
    id: 4,
    img: "assets/images/fz-8-pro-7.png",
    slug: "ShopDetails",
    title: "Mountain E-Bike, S3",
    price: 650,
  },
  {
    id: 5,
    img: "assets/images/fz-8-pro-8.png",
    slug: "ShopDetails",
    title: "Mountain E-Bike, S3",
    price: 650,
  },
  {
    id: 6,
    img: "assets/images/fz-8-pro-9.png",
    slug: "ShopDetails",
    title: "Mountain E-Bike, S3",
    price: 650,
  },
];

const cycleBlogData = [
  {
    id: 1,
    img: "assets/images/fz-8-blog-1.jpg",
    slug: "blogDetails",
    category: "Road Bike",
    title: "The key to getting really fast riding a mountain bike",
    date: "Jan 17, 2023",
    comments: 0,
  },
  {
    id: 2,
    img: "assets/images/fz-8-blog-2.jpg",
    slug: "blogDetails",
    category: "Road Bike",
    title: "The key to getting really fast riding a mountain bike",
    date: "Jan 17, 2023",
    comments: 0,
  },
  {
    id: 3,
    img: "assets/images/fz-8-blog-3.jpg",
    slug: "blogDetails",
    category: "Road Bike",
    title: "The key to getting really fast riding a mountain bike",
    date: "Jan 17, 2023",
    comments: 0,
  },
];

const brandData4 = [
  "assets/images/fz-8-brand-1.png",
  "assets/images/fz-8-brand-2.png",
  "assets/images/fz-8-brand-3.png",
  "assets/images/fz-8-brand-4.png",
  "assets/images/fz-8-brand-5.png",
  "assets/images/fz-8-brand-1.png",
  "assets/images/fz-8-brand-2.png",
  "assets/images/fz-8-brand-3.png",
];

const categoryData2 = [
  {
    id: 1,
    img: "assets/images/fz-9-category1.png",
    name: "Rompers",
    quantity: 5,
    slug: "#",
  },
  {
    id: 2,
    img: "assets/images/fz-9-category2.png",
    name: "Bodysuits",
    quantity: 7,
    slug: "#",
  },
  {
    id: 3,
    img: "assets/images/fz-9-category3.png",
    name: "Onesies",
    quantity: 8,
    slug: "#",
  },
  {
    id: 4,
    img: "assets/images/fz-9-category4.png",
    name: "Jumpsuits",
    quantity: 3,
    slug: "#",
  },
  {
    id: 5,
    img: "assets/images/fz-9-category1.png",
    name: "Rompers",
    quantity: 5,
    slug: "#",
  },
  {
    id: 6,
    img: "assets/images/fz-9-category2.png",
    name: "Bodysuits",
    quantity: 7,
    slug: "#",
  },
  {
    id: 7,
    img: "assets/images/fz-9-category3.png",
    name: "Onesies",
    quantity: 8,
    slug: "#",
  },
  {
    id: 8,
    img: "assets/images/fz-9-category4.png",
    name: "Jumpsuits",
    quantity: 3,
    slug: "#",
  },
];

const kidsClothingData = [
  {
    id: 1,
    img: "assets/images/fz-9-product1.png",
    name: "Jumpsuits",
    price: 10,
    category: ["hot-sale"],
  },
  {
    id: 2,
    img: "assets/images/fz-9-product2.png",
    name: "Onesies",
    price: 14,
    category: ["new-arrival", "offer"],
  },
  {
    id: 3,
    img: "assets/images/fz-9-product3.png",
    name: "Rain Wear",
    price: 15,
    category: ["new-arrival"],
  },
  {
    id: 4,
    img: "assets/images/fz-9-product4.png",
    name: "Jumpsuits",
    price: 10,
    category: ["hot-sale", "offer"],
  },
  {
    id: 5,
    img: "assets/images/fz-9-product5.png",
    name: "Onesies",
    price: 13,
    category: ["new-arrival", "hot-sale", "offer"],
  },
  {
    id: 6,
    img: "assets/images/fz-9-product6.png",
    name: "Rain Wear",
    price: 12,
    category: ["new-arrival", "hot-sale"],
  },
  {
    id: 7,
    img: "assets/images/fz-9-product7.png",
    name: "Jumpsuits",
    price: 10,
    category: ["hot-sale", "offer"],
  },
  {
    id: 8,
    img: "assets/images/fz-9-product8.png",
    name: "Onesies",
    price: 17,
    category: ["new-arrival"],
  },
];

const kidsBlogData = [
  {
    id: 1,
    img: "assets/images/fz-9-blog-1.jpg",
    category: "Fashion",
    title: "Trend Alert: Faux Fur | 8 of the best fluffy hand bags",
    date: "Jan 17, 2023",
    comments: 0,
  },
  {
    id: 2,
    img: "assets/images/fz-9-blog-2.jpg",
    category: "Fashion",
    title: "Why are Versace Jeans Couture bags so popular?",
    date: "Jan 17, 2023",
    comments: 0,
  },
  {
    id: 3,
    img: "assets/images/fz-9-blog-1.jpg",
    category: "Fashion",
    title: "Trend Alert: Faux Fur | 8 of the best fluffy hand bags",
    date: "Jan 17, 2023",
    comments: 0,
  },
  {
    id: 4,
    img: "assets/images/fz-9-blog-2.jpg",
    category: "Fashion",
    title: "Why are Versace Jeans Couture bags so popular?",
    date: "Jan 17, 2023",
    comments: 0,
  },
];

const kidsBrandData = [
  "assets/images/fz-9-brand1.png",
  "assets/images/fz-9-brand2.png",
  "assets/images/fz-9-brand3.png",
  "assets/images/fz-9-brand4.png",
  "assets/images/fz-9-brand5.png",
  "assets/images/fz-9-brand6.png",
  "assets/images/fz-9-brand1.png",
  "assets/images/fz-9-brand2.png",
  "assets/images/fz-9-brand3.png",
  "assets/images/fz-9-brand4.png",
  "assets/images/fz-9-brand5.png",
  "assets/images/fz-9-brand6.png",
];

const bagShopData = [
  {
    id: 1,
    img: "assets/images/feature-product-10.png",
    name: "Duffel Handbag",
    price: 300,
    category: ["offer"],
    discount: 30,
  },
  {
    id: 2,
    img: "assets/images/feature-product-10-2.png",
    name: "Duffel Handbag",
    price: 300,
    category: ["new-arrival"],
  },
  {
    id: 3,
    img: "assets/images/feature-product-10-3.png",
    name: "Duffel Handbag",
    price: 300,
    category: ["hot-sale"],
  },
  {
    id: 4,
    img: "assets/images/feature-product-10-4.png",
    name: "Duffel Handbag",
    price: 300,
    category: ["offer"],
    discount: 30,
  },
  {
    id: 5,
    img: "assets/images/feature-product-10-5.png",
    name: "Duffel Handbag",
    price: 300,
    category: ["hot-sale"],
  },
  {
    id: 6,
    img: "assets/images/feature-product-10-6.png",
    name: "Duffel Handbag",
    price: 300,
    category: ["new-arrival"],
  },
  {
    id: 7,
    img: "assets/images/feature-product-10-7.png",
    name: "Duffel Handbag",
    price: 300,
    category: ["hot-sale"],
  },
  {
    id: 8,
    img: "assets/images/feature-product-10-8.png",
    name: "Duffel Handbag",
    price: 300,
    category: ["new-arrival"],
  },
];

const bagBrandData = [
  "assets/images/fz-10-brand-1.png",
  "assets/images/fz-10-brand-2.png",
  "assets/images/fz-10-brand-3.png",
  "assets/images/fz-10-brand-4.png",
  "assets/images/fz-10-brand-5.png",
  "assets/images/fz-10-brand-1.png",
  "assets/images/fz-10-brand-2.png",
  "assets/images/fz-10-brand-3.png",
  "assets/images/fz-10-brand-4.png",
  "assets/images/fz-10-brand-5.png",
];

const ccTvCategoryData = [
  {
    id: 1,
    img: "assets/images/category-cctv-1.png",
    name: "Demo CCTV",
  },
  {
    id: 2,
    img: "assets/images/category-cctv-2.png",
    name: "Bullet CCTV",
  },
  {
    id: 3,
    img: "assets/images/category-cctv-3.png",
    name: "C-Mount CCTV",
  },
  {
    id: 4,
    img: "assets/images/category-cctv-4.png",
    name: "Infrared CCTV",
  },
  {
    id: 5,
    img: "assets/images/category-cctv-1.png",
    name: "Demo CCTV",
  },
  {
    id: 6,
    img: "assets/images/category-cctv-2.png",
    name: "Bullet CCTV",
  },
  {
    id: 7,
    img: "assets/images/category-cctv-3.png",
    name: "C-Mount CCTV",
  },
  {
    id: 8,
    img: "assets/images/category-cctv-4.png",
    name: "Infrared CCTV",
  },
];

const ccTvShopData = [
  {
    id: 1,
    img: "assets/images/feature-cctv1.png",
    name: "Dome CCTV",
    price: 200,
  },
  {
    id: 2,
    img: "assets/images/feature-cctv2.png",
    name: "Bullet CCTV",
    price: 100,
  },
  {
    id: 3,
    img: "assets/images/feature-cctv3.png",
    name: "C-Mount CCTV",
    price: 260,
  },
  {
    id: 4,
    img: "assets/images/feature-cctv4.png",
    name: "PTZ Pan-Tilt CCTV",
    price: 150,
  },
  {
    id: 5,
    img: "assets/images/feature-cctv5.png",
    name: "Day/Night CCTV",
    price: 290,
  },
  {
    id: 6,
    img: "assets/images/feature-cctv6.png",
    name: "Infrared CCTV",
    price: 160,
  },
  {
    id: 7,
    img: "assets/images/feature-cctv7.png",
    name: "Wireless CCTV",
    price: 430,
  },
  {
    id: 8,
    img: "assets/images/feature-cctv8.png",
    name: "HD CCTV",
    price: 290,
  },
];

const ccTvBlogData = [
  {
    id: 1,
    img: "assets/images/blog-img1.png",
    title:
      "Know About The CCTV Camera Types And Specifications That You Can Avail",
  },
  {
    id: 2,
    img: "assets/images/blog-img2.png",
    title: "Cctvs Are An Indispensable Part Of Our Lives In The Modern Era.",
  },
  {
    id: 3,
    img: "assets/images/blog-img1.png",
    title:
      "Know About The CCTV Camera Types And Specifications That You Can Avail",
  },
  {
    id: 4,
    img: "assets/images/blog-img2.png",
    title: "Cctvs Are An Indispensable Part Of Our Lives In The Modern Era.",
  },
  {
    id: 5,
    img: "assets/images/blog-img1.png",
    title:
      "Know About The CCTV Camera Types And Specifications That You Can Avail",
  },
];

const ccTvCompanyData = [
  "assets/images/footer-img1.png",
  "assets/images/footer-img2.png",
  "assets/images/footer-img3.png",
  "assets/images/footer-img4.png",
  "assets/images/footer-img1.png",
  "assets/images/footer-img2.png",
  "assets/images/footer-img3.png",
  "assets/images/footer-img4.png",
];

const bagShopBannerData = [
  {
    id: 1,
    img: "assets/images/fz-12-banner-img-1.jpg",
    title: "New Handbag",
    productName: "Tote Bag",
    productImg: "assets/images/fz-12-banner-prod-1.jpg",
    price: 380,
  },
  {
    id: 2,
    img: "assets/images/fz-12-banner-img-2.jpg",
    title: "Shoulder Bag",
    productName: "Tote Bag",
    productImg: "assets/images/fz-12-banner-prod-2.jpg",
    price: 380,
  },
  {
    id: 3,
    img: "assets/images/fz-12-banner-img-3.jpg",
    title: "Lunch Box Bag",
    productName: "Tote Bag",
    productImg: "assets/images/fz-12-banner-prod-3.jpg",
    price: 380,
  },
];

const bagShopData2 = [
  {
    id: 1,
    img: "assets/images/fz-12-prod-1.png",
    name: "Tote Bag",
    slug: "tote-bag",
    price: 280,
  },
  {
    id: 2,
    img: "assets/images/fz-12-prod-2.png",
    name: "Tote Bag",
    slug: "tote-bag",
    price: 280,
  },
  {
    id: 3,
    img: "assets/images/fz-12-prod-3.png",
    name: "Tote Bag",
    slug: "tote-bag",
    price: 280,
  },
  {
    id: 4,
    img: "assets/images/fz-12-prod-4.png",
    name: "Tote Bag",
    slug: "tote-bag",
    price: 280,
  },
  {
    id: 5,
    img: "assets/images/fz-12-prod-5.png",
    name: "Tote Bag",
    slug: "tote-bag",
    price: 280,
  },
  {
    id: 6,
    img: "assets/images/fz-12-prod-6.png",
    name: "Tote Bag",
    slug: "tote-bag",
    price: 280,
  },
  {
    id: 7,
    img: "assets/images/fz-12-prod-7.png",
    name: "Tote Bag",
    slug: "tote-bag",
    price: 280,
  },
  {
    id: 8,
    img: "assets/images/fz-12-prod-8.png",
    name: "Tote Bag",
    slug: "tote-bag",
    price: 280,
  },
];

const bagBlogData = [
  {
    id: 1,
    img: "assets/images/fz-12-blog-1.jpg",
    category: "Fashion",
    title: "Trend Alert: Faux Fur | 8 of the best fluffy hand bags",
    date: "Jan 17, 2023",
    comments: 0,
  },
  {
    id: 2,
    img: "assets/images/fz-12-blog-2.jpg",
    category: "Fashion",
    title:
      "Why are Versace Jeans Couture bags so popular? | MyBag's Complete Guide",
    date: "Jan 17, 2023",
    comments: 0,
  },
  {
    id: 3,
    img: "assets/images/fz-12-blog-1.jpg",
    category: "Fashion",
    title: "Trend Alert: Faux Fur | 8 of the best fluffy hand bags",
    date: "Jan 17, 2023",
    comments: 0,
  },
  {
    id: 4,
    img: "assets/images/fz-12-blog-2.jpg",
    category: "Fashion",
    title:
      "Why are Versace Jeans Couture bags so popular? | MyBag's Complete Guide",
    date: "Jan 17, 2023",
    comments: 0,
  },
];

const bagCompanyData = [
  "assets/images/fz-12-brand-1.png",
  "assets/images/fz-12-brand-2.png",
  "assets/images/fz-12-brand-3.png",
  "assets/images/fz-12-brand-4.png",
  "assets/images/fz-12-brand-5.png",
  "assets/images/fz-12-brand-1.png",
  "assets/images/fz-12-brand-2.png",
  "assets/images/fz-12-brand-3.png",
  "assets/images/fz-12-brand-4.png",
  "assets/images/fz-12-brand-5.png",
];

const shopData2 = [
  {
    id: 1,
    img: "assets/images/food29.png",
    name: "Lenskart Blu Hustlr Glasses",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi nesciunt culpa expedita aperiam, voluptates obcaecati explicabo, incidunt deleniti eos, quisquam libero? Aliquam rerum id laboriosam eos necessitatibus provident dolore doloribus!",
    price: 589,
    offer: true,
  },
  {
    id: 2,
    img: "assets/images/food30.png",
    name: "Lenskart Blu Hustlr Glasses",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi nesciunt culpa expedita aperiam, voluptates obcaecati explicabo, incidunt deleniti eos, quisquam libero? Aliquam rerum id laboriosam eos necessitatibus provident dolore doloribus!",
    price: 589,
  },
  {
    id: 3,
    img: "assets/images/food31.png",
    name: "Lenskart Blu Hustlr Glasses",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi nesciunt culpa expedita aperiam, voluptates obcaecati explicabo, incidunt deleniti eos, quisquam libero? Aliquam rerum id laboriosam eos necessitatibus provident dolore doloribus!",
    price: 589,
  },
  {
    id: 4,
    img: "assets/images/food29.png",
    name: "Lenskart Blu Hustlr Glasses",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi nesciunt culpa expedita aperiam, voluptates obcaecati explicabo, incidunt deleniti eos, quisquam libero? Aliquam rerum id laboriosam eos necessitatibus provident dolore doloribus!",
    price: 589,
    offer: true,
  },
  {
    id: 5,
    img: "assets/images/food30.png",
    name: "Lenskart Blu Hustlr Glasses",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi nesciunt culpa expedita aperiam, voluptates obcaecati explicabo, incidunt deleniti eos, quisquam libero? Aliquam rerum id laboriosam eos necessitatibus provident dolore doloribus!",
    price: 589,
  },
  {
    id: 6,
    img: "assets/images/food31.png",
    name: "Lenskart Blu Hustlr Glasses",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi nesciunt culpa expedita aperiam, voluptates obcaecati explicabo, incidunt deleniti eos, quisquam libero? Aliquam rerum id laboriosam eos necessitatibus provident dolore doloribus!",
    price: 589,
  },
  {
    id: 7,
    img: "assets/images/food29.png",
    name: "Lenskart Blu Hustlr Glasses",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi nesciunt culpa expedita aperiam, voluptates obcaecati explicabo, incidunt deleniti eos, quisquam libero? Aliquam rerum id laboriosam eos necessitatibus provident dolore doloribus!",
    price: 589,
    offer: true,
  },
  {
    id: 8,
    img: "assets/images/food30.png",
    name: "Lenskart Blu Hustlr Glasses",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi nesciunt culpa expedita aperiam, voluptates obcaecati explicabo, incidunt deleniti eos, quisquam libero? Aliquam rerum id laboriosam eos necessitatibus provident dolore doloribus!",
    price: 589,
  },
  {
    id: 9,
    img: "assets/images/food31.png",
    name: "Lenskart Blu Hustlr Glasses",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi nesciunt culpa expedita aperiam, voluptates obcaecati explicabo, incidunt deleniti eos, quisquam libero? Aliquam rerum id laboriosam eos necessitatibus provident dolore doloribus!",
    price: 589,
  },
  {
    id: 10,
    img: "assets/images/food29.png",
    name: "Lenskart Blu Hustlr Glasses",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi nesciunt culpa expedita aperiam, voluptates obcaecati explicabo, incidunt deleniti eos, quisquam libero? Aliquam rerum id laboriosam eos necessitatibus provident dolore doloribus!",
    price: 589,
    offer: true,
  },
  {
    id: 11,
    img: "assets/images/food30.png",
    name: "Lenskart Blu Hustlr Glasses",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi nesciunt culpa expedita aperiam, voluptates obcaecati explicabo, incidunt deleniti eos, quisquam libero? Aliquam rerum id laboriosam eos necessitatibus provident dolore doloribus!",
    price: 589,
  },
  {
    id: 12,
    img: "assets/images/food31.png",
    name: "Lenskart Blu Hustlr Glasses",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi nesciunt culpa expedita aperiam, voluptates obcaecati explicabo, incidunt deleniti eos, quisquam libero? Aliquam rerum id laboriosam eos necessitatibus provident dolore doloribus!",
    price: 589,
  },
  {
    id: 13,
    img: "assets/images/food29.png",
    name: "Lenskart Blu Hustlr Glasses",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi nesciunt culpa expedita aperiam, voluptates obcaecati explicabo, incidunt deleniti eos, quisquam libero? Aliquam rerum id laboriosam eos necessitatibus provident dolore doloribus!",
    price: 589,
    offer: true,
  },
  {
    id: 14,
    img: "assets/images/food30.png",
    name: "Lenskart Blu Hustlr Glasses",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi nesciunt culpa expedita aperiam, voluptates obcaecati explicabo, incidunt deleniti eos, quisquam libero? Aliquam rerum id laboriosam eos necessitatibus provident dolore doloribus!",
    price: 589,
  },
  {
    id: 15,
    img: "assets/images/food31.png",
    name: "Lenskart Blu Hustlr Glasses",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi nesciunt culpa expedita aperiam, voluptates obcaecati explicabo, incidunt deleniti eos, quisquam libero? Aliquam rerum id laboriosam eos necessitatibus provident dolore doloribus!",
    price: 589,
  },
];

export {
  firstBannerData,
  brandList,
  thirdBannerData,
  accordionList,
  allProductList,
  blogList,
  ornamentList,
  allCakeList,
  productDetail,
  electricalProduct,
  ledBulbData,
  compactDeviceData,
  testimonialData,
  brandData,
  categoryData,
  brandData2,
  bannerData,
  carPartsData,
  blogData,
  watchData,
  blogData2,
  brandData3,
  bannerData2,
  cycleData,
  cycleBlogData,
  brandData4,
  categoryData2,
  kidsClothingData,
  kidsBlogData,
  kidsBrandData,
  bagShopData,
  bagBrandData,
  ccTvCategoryData,
  ccTvShopData,
  ccTvBlogData,
  ccTvCompanyData,
  bagShopBannerData,
  bagShopData2,
  bagBlogData,
  bagCompanyData,
  shopData2,
};
